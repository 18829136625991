<template>
  <div class="test">
    <p class="test__number" v-if="finishedRandomSort">{{ current + 1 }} / {{ vocabularies.length }}</p>
    <div v-if="finishedRandomSort" :class="isQuestion ? 'test__card' : 'test__card--answer'">
      <template v-if="isQuestion">
        <p class="test__card__question">{{ vocabularies[current].question }}</p>
      </template>
      <template v-else>
        <p class="test__card__answer">Câu trả lời : <br>{{ vocabularies[current].answer }}</p>
        <p class="test__card__mean">Ý nghĩa<br>{{ vocabularies[current].mean }}</p>
      </template>
    </div>
    <div v-if="finishedRandomSort" class="test__action">
      <button :class="canBack ? 'test__action__btn' : 'test__action__btn--cannot'"
      @click="next(false)" :disabled=!canBack>Quay lại</button>
      <button class="test__action__btn" @click="isQuestion = !isQuestion">Đáp án</button>
      <button :class="canNext ? 'test__action__btn' : 'test__action__btn--cannot'"
      @click="next(true)" :disabled=!canNext>Đi tiếp</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      // 問題かどうか
      isQuestion: true,
      // 単語帳
      vocabularies: [],
      // 今の位置
      current: 0,
      // ランダム並び替え終了フラグ
      finishedRandomSort: false
    }
  },
  mounted () {
    // クエリが正しいかの確認
    if (!['N5', 'N4', 'N3', 'N2'].includes(this.level) || !['名詞', '動詞', '形容詞'].includes(this.genre)) {
      window.location.href = '/notfound'
    }

    // 単語帳の取得
    this.vocabularies = this.getVocabularies()(this.level)[this.genre]
    if (this.vocabularies.length === 0) {
      // 単語の取得
      this.addVocabularies(this.level).then(() => {
        this.vocabularies = this.getVocabularies()(this.level)[this.genre]
        this.sortRondom(this.vocabularies)
        this.finishedRandomSort = true
      })
    } else {
      this.sortRondom(this.vocabularies)
      this.finishedRandomSort = true
    }
  },
  computed: {
    /**
     * @return {String} 日本語レベル N5 - N2のいずれか
     */
    level () {
      return this.$route.query.level
    },
    /**
     * @return {String} ジャンル 名詞、動詞、形容詞のいずれか
     */
    genre () {
      return this.$route.query.genre
    },
    /**
     * @return {Boolean} 単語帳を戻れるか
     */
    canBack () {
      return this.current !== 0
    },
    /**
     * @return {Boolean} 単語帳を次に行けるか
     */
    canNext () {
      return this.current + 1 !== this.vocabularies.length
    }
  },
  methods: {
    ...mapGetters('vocabulary', ['getVocabularies']),
    ...mapActions('vocabulary', ['addVocabularies']),
    /**
     * 次へ or 戻る
     * @param {Boolean} isNext 次の単語を表示するか
     */
    next (isNext) {
      this.isQuestion = true
      isNext ? this.current++ : this.current--
    },
    /**
     * ランダムに配置変え Fisher–Yatesアルゴリズム使用
     * @param {Array} ランダムにソートする対象の配列
     */
    sortRondom (targets) {
      for (let i = targets.length - 1; i > 0; i--) {
        const r = Math.floor(Math.random() * (i + 1))
        const tmp = targets[i]
        targets[i] = targets[r]
        targets[r] = tmp
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.test {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  margin-top: 100px;
  margin-bottom: $footer_height;
  width: 100%;
  &__number {
    margin: 0;
    margin-bottom: 12.5px;
    font-size: 15px;
    color: #707070;
  }
  &__card {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    flex-direction: column;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    width: 82vw;
    padding: 27px 16.5px;
    border-radius: 8px;
    box-shadow: 3px 3px 6px 0 rgba(17, 51, 72, 0.16);
    box-sizing: border-box;
    &--answer {
      @extend .test__card;
      -webkit-justify-content: center;
      justify-content: center;
      align-items: start;
    }
    &__question {
      width: 100%;
      margin: 0;
      font-size: 35px;
      font-weight: bold;
      color: #393939;
      text-align: center;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: pre-wrap;
    }
    &__answer {
      width: 100%;
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      color: #393939;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
    &__mean {
      width: 100%;
      margin: 9px;
      font-size: 13px;
      color: #A5A5A5;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  }
  &__action {
    display: flex;
    justify-content: space-between;
    width: 82vw;
    margin-top: 18.8pt;
    text-align: center;
    &__btn {
      height: 40px;
      width: 80px;
      line-height: 40px;
      font-size: 8.5pt;
      font-weight: bold;
      color: white;
      background-color: #FFA758;
      border-radius: 2.5pt;
      &--cannot {
        @extend .test__action__btn;
        background-color: #a5a5a5;
      }
    }
  }
}
</style>
